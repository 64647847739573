<template>
  <div class="header">
    <b-form @submit.prevent="onSubmit">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h2>{{ title }}</h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="9">
            <b-form-group>
              <label v-if="this.isFanApp()" class="sr-only" for="search_term">
                City, town, postcode or Admiral Nurse service name
              </label>
              <label v-if="this.isFasApp()" class="sr-only" for="search_term">
                City, town, postcode or group/service name
              </label>
              <b-row>
                <b-col sm="11">
                  <b-form-input
                    id="search_term"
                    name="search_term"
                    :placeholder="this.placeHolder"
                    v-model.trim="$v.searchTerm.$model"
                    :state="validateSearchTerm()"
                  ></b-form-input>
                </b-col>
                <b-col sm="1">
                  <button id="locate" class="btn-image" @click.prevent="resetLocation()" type="button">
                    <img src="../assets/locate.png" alt="Find current location" title="Find current location">
                  </button>
                </b-col>
              </b-row>
              <div v-if="this.isFasApp()">
                <div class="mb-2">
                  <b-form-select v-model="serviceAudienceId" :options="this.serviceAudiences"></b-form-select>
                </div>

                <div>
                  <b-form-select v-model="serviceTypeId" :options="this.serviceTypes"></b-form-select>
                </div>
              </div>

              <div class="filter-label" v-if="this.isFanApp()">
                <b-form-checkbox
                  id="rbl_checkbox"
                  v-model="serviceAudienceId"
                  name="rbl_checkbox"
                  value="6"
                  unchecked-value=""
                >
                  If you or your dependants have served in the forces please check this box to see Royal British Legion
                  specific services
                </b-form-checkbox>
              </div>

              <b-form-invalid-feedback>
                Please enter a valid search term.
              </b-form-invalid-feedback>

            </b-form-group>
          </b-col>
          <b-col sm="1" class="text-right">
            <b-button type="submit">GO</b-button>
          </b-col>
          <b-col sm="1" class="text-right">
            <b-button type="button" @click.prevent="clearFilters()" class="ml-1">CLEAR</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {gmaps} from 'x5-gmaps';

const {APPLICATION_SCOPE} = require('../config');

export default {
  name: "Header",
  data() {
    return {
      geocoder: null,
    }
  },
  computed: {
    ...mapGetters([
      'serviceAudiences',
      'serviceTypes',
      'applicationScope',
      'currentLocation'
    ]),
    serviceAudienceId: {
      get() {
        return this.$store.state.selectedServiceAudienceId;
      },
      set(value) {
        this.setSelectedServiceAudienceId(value);
      }
    },
    serviceTypeId: {
      get() {
        return this.$store.state.selectedServiceTypeId;
      },
      set(value) {
        this.setSelectedServiceTypeId(value);
      }
    },
    searchTerm: {
      get() {
        return this.$store.state.searchTerm;
      },
      set(value) {
        this.setSearchTerm(value);
      }
    },
    placeHolder: {
      get() {
        if (this.isFanApp()) {
          return "City, town, postcode or Admiral Nurse service"
        } else {
          return "Your postcode or group/service name"
        }
      }
    },
  },
  props: {
    title: String
  },
  validations: {
    searchTerm: {
      required
    }
  },
  methods: {
    isFasApp() {
      return this.applicationScope === APPLICATION_SCOPE.FAS;
    },
    isFanApp() {
      return this.applicationScope === APPLICATION_SCOPE.FAN;
    },
    validateSearchTerm() {
      const {$dirty, $error} = this.$v.searchTerm;
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.doMainSearch();
    },
    doMainSearch() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.returnToServices()
          .then(() => this.gotoLocationForSearchString(this.searchTerm))
          .then(() => this.searchServices(this.searchTerm))
          .then(() => this.clearSelectedService())
          .then(() => this.calculateSearchResultsDistances())
          .then(() => this.pruneResultsOverDistance())
          .then(() => this.sortSearchResults())
          .catch((e) => {
            this.clearLocation();
            this.showServices();
            console.log(e.message);
          });
      }
    },
    /* Cascade through the different types of service */
    searchServices(searchTerm) {
      return new Promise((resolve, reject) => {
        this.searchServiceName(searchTerm)
          .then(() => resolve())
          .catch(() => {
            this.loadServices()
              .then(() => this.searchLocation(this.currentLocation))
              .then(() => resolve())
              .catch(e => reject(e));
          });
      });
    },
    gotoLocationForSearchString(address) {
      return new Promise((resolve, reject) => {
        this.geocoder.geocode({
          'address': address + ', United Kingdom',
          'region': 'GB'
        }, (results, status) => {
          if (status === 'OK') {
            this.setCurrentLocation(results[0].geometry.location);
            resolve();
          } else {
            reject(new Error('Address unknown.'));
          }
        }).catch(e => reject(e));
      });
    },
    resetLocation() {
      this.returnToServices()
        .then(() => this.setSearchTerm(""))
        .then(() => this.clearSelectedService())
        .then(() => this.clearLocation())
        .then(() => this.locate())
        .then(() => this.searchServices())
        .then(() => this.calculateSearchResultsDistances())
        .then(() => this.pruneResultsOverDistance())
        .then(() => this.sortSearchResults())
        .catch((e) => {
          this.clearLocation();
          this.showServices();
          console.log(e.message);
        });
    },
    returnToServices() {
      return new Promise((resolve) => {
        if (this.$route.name === 'Services') {
          resolve();
        } else {
          this.$router.push({name: 'Services'})
            .then(() => resolve());
        }
      });
    },
    clearFilters() {
      this.searchTerm = null;
      this.serviceAudienceId = null;
      this.serviceTypeId = null;
      this.$v.$reset();
      return;
    },
    ...mapActions([
      'clearLocation',
      'loadServices',
      'locate',
      'searchLocation',
      'searchServiceName',
      'showServices',
      'calculateSearchResultsDistances',
      'pruneResultsOverDistance',
      'sortSearchResults',
      'clearSelectedService',
      'loadServiceTypes',
      'loadServiceAudiences',
      'setSelectedServiceAudienceId',
      'setSelectedServiceTypeId',
      'setSearchTerm',
      'setCurrentLocation'
    ]),
  },
  mounted() {
    gmaps()
      .then(maps => {
        this.geocoder = new maps.Geocoder();
      })
      .then(() => {
        this.loadServiceAudiences();
        this.loadServiceTypes();
      })
      .then(() => {
        if (this.$route.name === 'Services' && this.searchTerm) {
          this.doMainSearch();
        }
      });
  },
}
</script>

<style lang="scss" scoped>
.header {
  background-color: var(--primary);
  min-height: 155px;
  padding-bottom: 10px;

  .btn {
    border-color: #fff;
    padding: 0.375rem 1rem;

    &:active {
      border-color: #fff !important;
    }
  }

  .btn-image {
    background: none;
    border: none;
    padding: 0 0 2em 0;
  }

  h2 {
    color: #fff;
    margin: 10px 0;
    font-size: 1.75rem;
    line-height: 1.35;

    @include media-breakpoint-up(md) {
      margin: 20px 0 30px;
      font-size: 2.25rem;
      line-height: 1.2;
    }
  }

  .invalid-feedback {
    font-size: 100%;
  }
}
</style>
